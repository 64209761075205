<template>
  <v-app>
    <div
      class="bg-body d-flex flex-column justify-center align-center min-vh-100"
    >
      <div class="sign-up-card">
        <div class="sign-up-card-container">
          <v-img
            src="@/assets/images/logo.svg"
            height="80px"
            contain
            class="mb-10"
          />
          <div class="text-center mb-6">
            <h3 class="mb-2">Recuperar senha</h3>
          </div>
          <template v-if="validToken">
            <template v-if="success">

              <v-alert type="success" class="mb-4">
                Senha redefinida com sucesso
              </v-alert>

              <div class="mb-4 d-flex flex-column justify-center">
                <v-btn
                  block
                  color="primary"
                  class="text-capitalize font-600"
                  @click="$router.push('/')"
                >
                  Fazer login
                </v-btn>
              </div>


            </template>
            <template v-else>
              <v-form v-model="valid">
                <div class="mb-4">
                  <v-text-field
                    v-model="password"
                    label="Senha"
                    type="password"
                    placeholder="*********"
                    outlined
                    dense
                    hide-details=""
                    :disabled="loading"
                    class="mb-4"
                    :rules="passwordRules"
                  />
                  <v-text-field
                    v-model="confirmPassword"
                    label="Confirmar senha"
                    type="password"
                    placeholder="*********"
                    outlined
                    dense
                    hide-details=""
                    :disabled="loading"
                    class="mb-4"
                    :rules="passwordRules"
                  />
                </div>
              </v-form>

              <v-alert v-if="error" type="error" class="mb-4">
                {{ error }}
              </v-alert>

              <div class="mb-4 d-flex flex-column justify-center">
                <v-btn
                  block
                  color="primary"
                  class="text-capitalize font-600"
                  :disabled="!valid"
                  :loading="loading"
                  @click="processResetPassword"
                >
                  Redefinir senha
                </v-btn>
              </div>
            </template>
          </template>
          <template v-else>
            <v-alert v-if="loading" type="info" class="mb-4">
              Carregando...
            </v-alert>

            <v-alert v-if="error" type="error" class="mb-4">
              Link invalido ou expirado
            </v-alert>
          </template>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import AUTH from "@/services/auth";

export default {
  data() {
    return {
      validToken: false,
      valid: false,
      loading: false,
      error: null,
      success: false,

      password: "",
      confirmPassword: "",

      passwordRules: [
        (v) => !!v || "Senha é obrigatória",
        (v) => v.length >= 8 || "Senha deve ter no mínimo 8 caracteres",
      ],
    };
  },
  methods: {
    async readToken() {
      try {
        this.loading = true;
        this.error = null;

        const { token } = this.$route.params;

        await AUTH.resetPassword.readToken(token);

        this.validToken = true;
      } catch (error) {
        console.log(error);
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    async processResetPassword() {
      try {
        this.loading = true;
        this.error = null;

        if (this.password !== this.confirmPassword)
          throw new Error("Senhas não são iguais");

        const { token } = this.$route.params;

        await AUTH.resetPassword.useToken(token, this.password);

        this.success = true;

        const response = await this.login(this.form);
        if (!!response) this.$router.push("/app");
      } catch (error) {
        console.log(error);
        this.error = error.message || error;
        this.loading = false;
      }
    },
  },
  mounted() {
    this.readToken();
  },
};
</script>

<style lang="scss" scoped>
.sign-up-card {
  width: 500px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 8px;
  margin: 2rem auto;
  box-shadow: rgb(3 0 71 / 9%) 0px 8px 45px;
  @media (max-width: 500px) {
    width: 100%;
  }
  .sign-up-card-container {
    padding: 3rem 3.75rem;
    @media (max-width: 500px) {
      padding: 3rem 1rem 0px;
    }
  }
}
</style>
